export default {
  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'light',
      borderBottom: '2px solid',
      borderColor: 'primary',
      padding: ['0.5rem', '0.5rem', '1rem', '1rem'],
      zIndex: '1001'
    },
    '.logoLocationContainer': {
      order: '-1'
    },
    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: 'dark',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.hamburger': {
      border: 'solid 1px',
      borderColor: 'secondary',
      padding: ' 0.35rem 0.25rem',
      width: '40px',
      height: '40px',
      '> div': {
        backgroundColor: 'primary'
      }
    },

    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['45px', '55px', '65px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['45px', '55px', '65px']
      }
    },

    '.navMenuLogo': {
      position: 'static',
      width: ['100%', '', '100%'],
      padding: ['0.5rem', '', '2rem'],
      // borderBottom: '1px solid',
      // borderColor: 'secondary',
      margin: '0rem',
      textAlign: 'center',
      img: {
        maxHeight: ['55px', '', '100px', '']
      }
    },

    '.navItem': {
      color: 'dark',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      textAlign: 'center',
      a: {
        fontWeight: '600',
        '&:hover': {
          color: 'primary'
        }
      }
    },
    '.navMenuOpen': {
      backgroundColor: 'background',
      width: ['90%', '', '40%'],
      alignItems: 'center',
      justifyContent: ['flex-start', 'flex-start', 'flex-start'],
      padding: '0rem',
      '.seperatorLine': {
        // display: 'none',
        width: '100%',
        margin: '0.5rem 0rem',
        padding: '0rem'
      },
      '.navItem': {
        variant: 'customVariants.title',
        display: 'flex',
        alignItems: 'center',
        order: 'unset',
        marginBottom: '0rem',
        a: {
          fontSize: ['1.1rem', '1.25rem', '1.5rem', '1.75rem'],
          textTransform: 'capitalize'
        }
      }
    },
    '.navBlockOpen': {
      position: 'fixed',
      left: '0rem',
      top: '0rem',
      width: '100%',
      height: '100vh',
      backdropFilter: 'blur(2px)',
      backgroundColor: 'rgb(30 24 6 / 70%)'
    },
    '.phoneSocialContainer': {
      // width: '100%',
      // maxWidth: 'unset',
      // backgroundColor: 'secondary',
      // paddingBottom: '1rem',
      color: 'dark',
      borderColor: 'dark',
      flexDirection: ['row', '', 'row', '']
    },
    '.socialIconsContainer, .phoneContainer': {
      borderColor: 'dark',
      border: 'none',
      svg: {
        path: {
          fill: 'dark'
        }
      },
      '.smallNavHeading': {
        // fontSize: ['1.1rem', '1.25rem'],
        // color: '#5d5833',
        // fontWeight: 'bold',
        // textTransform: 'capitalize',
        // padding: '0.25rem',
        // borderTop: 'solid 1px',
        // borderBottom: 'solid 1px',
        // width: '100%',
        // marginBottom: '0rem',
        // textAlign: 'left',
      },
      // margin: '0rem',
      // alignItems: 'center',
      // justifyContent: 'flex-start',
      // color: 'light',
      // border: 'none',
      // width: '100%',
      // fontSize: '1rem',
      // maxWidth: 'unset',
      // padding: '0rem',
      a: {
        // textAlign: 'left',
        // justifyContent: 'flex-start',
      }
    },

    '.containerScrolled': {
      backgroundColor: 'background',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: 'primary'
      }
    }
  },

  footer: {
    borderTop: '1px solid lightgrey',
    backgroundColor: 'secondary',
    color: 'black',
    '.socialContainer, .contactDetails-container': {
      svg: {
        path: {
          fill: 'black'
        }
      }
    },
    '.aboutText': {
      color: 'black'
    },

    '.sectionHeading': {
      color: 'black',
      fontWeight: '500',
      borderColor: 'black'
    },

    '.poweredBy .gonationLogo': {
      filter: 'brightness(0)'
    },
    '.copyright': {
      backgroundColor: 'transparent',
      color: 'black'
    },
    '.multiButtonContainer': {
      justifyContent: ['center', '', '', 'flex-start'],
      a: {
        color: 'black'
      }
    },
    '.ctaWidgetContainer': {
      background: 'black'
    }
  },

  ctaWidget: {
    backgroundColor: 'black',
    a: {
      backgroundColor: 'dark'
    },
    'a:hover': {
      color: 'primary',
      backgroundColor: 'dark'
    }
  },

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    marginBottom: '1.5rem',
    fontSize: '2.5rem',
    color: 'dark',
    fontWeight: '500',
    whiteSpace: 'pre-wrap',
    order: '2',
    '::before': {
      content: "''",
      width: '40px',
      height: '2px',
      backgroundColor: 'black',
      margin: '0rem 1rem'
    },
    '::after': {
      content: "''",
      width: '40px',
      height: '2px',
      backgroundColor: 'black',
      margin: '0rem 1rem'
    }
  },

  subtitle: {
    fontWeight: 'bold',
    textTransform: 'initial',
    fontFamily: 'body',
    fontSize: '1.25rem',
    textTransform: 'capitalize',
    color: 'primary',
    order: '1'
  },

  text: {
    order: '3'
  },

  sideBySide1: {
    margin: '2rem 0rem',
    height: ['', '', '70vh'],
    '.content': {
      order: '1',
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'center',
      textAlign: 'center',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.lazyload-wrapper': {
      order: '2',
      width: ['', '', '55%'],
      '::before': {
        background: 'unset'
      }
      // img: {
      //   minHeight: '50vh',
      // },
    },
    '.ctaButton': {
      variant: 'buttons.secondary'
    }
  },

  sideBySide2: {
    variant: 'customVariants.sideBySide1',
    '.lazyload-wrapper': {
      order: '0',
      width: ['', '', '55%']

      // img: {
      //   height: '100vh',
      // },
    }
  },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.section': {
      border: 'none',
      width: ['100%', '', '65%'],
      maxWidth: 'unset',
      margin: '0rem auto',
      height: 'auto',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      textAlign: 'center'
      // textShadow: '2px 2px 10px black',
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'light',
      borderLeft: 'unset',
      filter: 'brightness(0) invert(1)', // !makes everything white
      fontSize: ['2rem', '2.5rem', '3.5rem', '4rem'],
      marginBottom: '10vh',
      width: 'auto',
      justifyContent: 'center',
      alignItems: 'center'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      fontSize: ['1.25rem', '1.5rem', '1.75rem', '2rem']
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },
  homepageHeroShout: {
    display: 'none'
  },

  homepageShout: {
    margin: '2rem 0rem',
    height: ['', '', '70vh'],
    '.content': {
      padding: ['1rem', '', '', '4rem', '5rem'],
      alignItems: 'center',
      textAlign: 'center'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text',
      margin: '1rem 0rem 2rem',
      maxWidth: 'unset'
    },
    '.date': {
      margin: '1rem 0rem',
      color: 'dark',
      opacity: '0.7',
      order: '2'
    },
    '.shoutCTABtns': {
      order: '5',
      '.shoutCTA': {
        variant: 'buttons.primary',
        padding: '1rem',
        fontSize: '1rem'
      }
    }
  },

  homepageAbout: {
    variant: 'customVariants.homepagePrivateEvents',
    height: 'auto',
    '.content': {
      width: '100%',
      maxWidth: '800px',
      mx: 'auto',
      textAlign: 'center',
      alignItems: 'center',
      height: 'auto'
    }
  },

  homepageMenu: {
    variant: 'customVariants.sideBySide2'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1'
  },

  homepagePrivateEvents: {
    variant: 'customVariants.sideBySide2'
  },

  homepageContact: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    padding: ['3rem 1rem', '', '6rem 1rem', '7rem 1rem'],
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? =====  Menu page  =====
  // ? ========================

  menu: {
    // border: 'solid 1px black',
    padding: ['1rem', '1rem'],
    margin: '1rem auto',
    minHeight: '50vh',
    width: ['calc(100% - 1rem)'],
    '.innerMenuContainer': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column'
    },
    backgroundColor: 'white',
    '.backToMenuBtn': {
      backgroundColor: 'dark'
    },
    '.menuContainer': {
      paddingBottom: '1rem'
    },
    '.tabsRow': {
      padding: '0rem 0rem 1rem',
      marginBottom: '2rem'
    },
    '.menuCell': {
      border: 'none',
      boxShadow: '0 0 12px rgba(0,0,0,.2)',
      width: '100%',
      background: 'white',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transition: 'all .2s',
      minHeight: '300px',
      '&:hover': {
        transition: 'all .2s',
        boxShadow: '0 0 24px rgba(0,0,0,.2)'
      }
    },
    '.cellImageContainer': {
      display: 'none'
    },
    '.cellName': {
      textAlign: 'center',
      fontFamily: 'primary',
      fontWeight: 'normal'
    },
    '#menuTab': {
      flexGrow: '1',
      width: 'fit-content',
      flexGrow: '1',
      display: 'flex',
      maxWidth: 'unset',
      minWidth: 'unset',
      textAlign: 'center',
      fontWeight: 'bold',
      justifyContent: 'center',
      ':hover': {
        backgroundColor: 'primary',
        opacity: '0.7'
      }
    },
    '.menuItemInnerContainer, .menuItemContentContainer': {
      margin: '0rem'
    },
    '.menuSectionTitle': {
      textTransform: 'capitalize',
      color: 'primary',
      borderBottom: 'solid 2px',
      borderColor: 'secondary',
      margin: ['1rem', '', '1rem 1.5rem'],
      paddingBottom: '1rem',
      textAlign: 'left',
      fontSize: ['1.5rem', '', '2rem']
    },

    '.menuItemName': {
      color: '#000',
      fontFamily: 'heading'
    },
    '.dropdownContainer': {
      display: 'none'
    },

    '.menuSectionDescription': {
      fontSize: '1.1rem',
      textAlign: 'left',
      margin: ['1rem', '', '1rem 1.5rem']
    },
    '.menuItemDescription': {
      color: 'dark'
    },
    '.menuPriceContainer': {
      fontFamily: 'heading'
      // display: 'none',
    },
    '.variantsContainer': {
      // display: 'none',
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumsContainer': {
      border: 'none',
      // display: 'none',
      '.albumName': {
        color: '#111'
      }
    },
    '.albumTitle': {
      variant: 'buttons.secondary',
      backgroundColor: 'transparent',
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.no-events-container': {
      img: {
        // filter: 'brightness(1) invert(1)',
      }
    },
    '.eventItemImage': {
      minHeight: 'unset',
      maxHeight: 'unset',
      height: '100%'
    },
    '.eventDaysContainer': {
      span: {
        color: 'dark'
      }
    },
    '.eventDays, .eventDate': {
      color: 'black'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    padding: '4rem 1rem',
    order: '4',
    '.title': {
      display: 'flex',
      flexDirection: 'column',
      fontSize: '2.5rem',
      color: 'primary',
      fontWeight: '500',
      whiteSpace: 'pre-wrap',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      marginBottom: '2rem'
    },
    '.subtitle': {
      textTransform: 'initial',
      fontSize: '1rem',
      opacity: '0.8',
      marginBottom: '4rem'
    },
    form: {
      input: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },

      textarea: {
        border: 'none',
        borderRadius: '0px',
        borderBottom: '1px solid grey'
      },
      '.submitBtn': {
        variant: 'buttons.secondary',
        // borderRadius: '0px',
        textAlign: 'center',
        justifyContent: 'center'
      }
    }
  },

  locationMap: {
    order: '2',
    backgroundColor: '#fff',
    color: 'dark',
    h3: {
      textTransform: 'uppercase',
      fontSize: '2rem',
      borderBottom: '2px solid',
      borderColor: 'white'
    },

    '.dayofWeekText': {
      margin: '0 auto 0 0'
    }
  },

  // ? ========================
  // ? ====  Events Menu  ====
  // ? ========================

  privateEventIntro: {
    background: 'unset',
    '.section': {
      order: '1',
      padding: ['2rem', '', '', '1.5rem 3.5rem', '2.5rem 4.5rem'],
      alignItems: 'center',
      textAlign: 'center',
      '.title': {
        variant: 'customVariants.title'
      },
      '.subtitle': {
        variant: 'customVariants.subtitle'
      },
      '.text': {
        variant: 'customVariants.text'
      },
      a: {
        order: '4'
      }
    },
    '.ctaButton': {
      variant: 'buttons.primary'
    }
  },

  // ? ========================
  // ? ====  Press Menu  ====
  // ? ========================

  pressMenu: {},

  pressPage: {},

  // ? ========================
  // ? ====  Team Menu  ====
  // ? ========================

  teamMenu: {},

  teamPage: {},

  // ? ========================
  // ? ====  blog Menu  ====
  // ? ========================

  blogMenu: {},

  blogPage: {}
}
